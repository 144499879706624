const config = {
    isProduction: false,
    useEmulators: !!process.env.REACT_APP_EMULATORS,

    firebaseFunctionEndpoint: 'https://europe-west1-johan-friends-dev.cloudfunctions.net/',
    swishEndpoint: 'https://mpc.getswish.net/qrg-swish/api',
    functions: {
        clearStripeTerminal: 'https://clearstripeterminal-x4emaa5cla-ew.a.run.app',
        sendConfirmationEmail: 'http://127.0.0.1:5001/johan-friends-dev/europe-west1/sendConfirmationEmail',
    },

    baseDomains: [
        'slicesize.com',
        'slicesize.se',
        'localhost',
        'johan-friends-dev.web.app',
        'dev.slicesize.com',
        'dev.slicesize.se',
        'app.slicesize.com',
        'dev.johanandfriends.com',
        'app.johanandfriends.com',
    ],
}

if (process.env.REACT_APP_ENV) {
    config.isProduction = process.env.REACT_APP_ENV === 'production';
} else {
    config.isProduction = process.env.NODE_ENV === 'production'
}

if (config.useEmulators) {
    config.firebaseFunctionEndpoint = 'http://localhost:5001/johan-friends-dev/europe-west1';

    config.functions.clearStripeTerminal = 'http://127.0.0.1:5001/johan-friends-dev/europe-west1/clearStripeTerminal'
    config.functions.sendConfirmationEmail = 'http://127.0.0.1:5001/johan-friends-dev/europe-west1/sendConfirmationEmail';
}

if (config.isProduction) {
    config.firebaseFunctionEndpoint = 'https://europe-west1-johan-friends.cloudfunctions.net';
    config.swishEndpoint = 'https://cpc.getswish.net/swish-cpcapi';

    config.functions.clearStripeTerminal = 'https://clearstripeterminal-x4emaa5cla-ew.a.run.app'
    config.functions.sendConfirmationEmail = 'https://sendconfirmationemail-x4emaa5cla-ew.a.run.app';
}


export default config;