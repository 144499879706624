export enum CUSTOMER_NAV {
    PRODUCTS = 'products',
    ORDER = 'order',
    SWISH_DESKTOP = 'swish-desktop',
    CARD_PAYMENT = 'card-payment',
    CONFIRM = 'confirm',
    TERMS = 'terms',
    PRIVACY = 'privacy',
    MY_ORDERS = 'my-orders',
    MY_BENEFITS = 'my-benefits',
    OFFER = 'offer',
    CARD = 'card',
}

export enum STAFF_NAV {
    RESTAURANT = 'restaurant',
    PRODUCTS = 'products',
    DEALS = 'deals',
    ORDERS = 'orders',
    AUDIT_LOG = 'audit'
}

export enum STAFF_SUBNAVIGATION {
    RESTAURANT_OPENNING_HOURS = 'openning-hours',
    RESTAURANT_FEATURES = 'features',
    RESTAURANT_BRANDING = 'branding',
    RESTAURANT_ACCOUNTING = 'accounting',
    RESTAURANT_DEVICE = 'device',

    ORDERS_ANALYTICS = 'analytics',
    ORDERS_FEEDBACK = 'feedback',
    ORDERS_DAY_REPORTS = 'day-reports',

    PRODUCTS_COUNTERS = 'counters',
}

// Staff
export const LOGIN = 'login';
export const STAFF = 'staff';



export const DISCORD_LINK = 'https://discord.gg/w5ztrkgu5w'
