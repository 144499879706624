export enum Collections {
    restaurants = 'restaurants',
    orders = 'orders',
    products = 'products',
    deals = 'deals',
    analytics = 'analytics',
    feedback = 'feedback',
    receipts = 'receipts',
    dayreports = 'dayreports',
    userdeals = 'userdeals',
    auditlog = 'auditlog',
    users = 'users',
}
