import React, { useContext, useState, useEffect, useMemo } from "react"
import { AuthContext, AuthUser } from "../models/Auth";
import { signInWithEmailLink } from "firebase/auth";
import { useDB, useFirebaseAuth } from "./FirebaseContext";
import Loading from "../components/Loading";
import { doc, setDoc } from "@firebase/firestore";
import { onSnapshot } from "firebase/firestore";
import User from "../models/User";
import { fromFirebaseDoc } from "../utils/shared/firebase";
import useQueryParam from "../hooks/useQueryParams";
import FinishEmailLoginModal from "../components/modals/FinishEmailLoginModal";
import { LOCAL_STORAGE_KEYS } from "../constants/localStorage";
import { useText } from "./TextContext";
import { toast } from "react-toastify";
import UserDeals from "../models/UserDeals";
import { Collections } from "../constants/firestore";


const authContext = React.createContext<AuthContext>({} as any);

export function useAuth() {
    return useContext(authContext)
}

export function AuthProvider({ children }: any) {
    const auth = useFirebaseAuth();
    const db = useDB();
    const [currentUser, setCurrentUser] = useState<AuthUser | null>(null)
    const [userDeals, setUserDeals] = useState<UserDeals>({ dealTrackers: {} });
    const [loading, setLoading] = useState(true)
    const { finishEmailSignup } = useQueryParam();
    const [showFinishEmailSignup, setShowFinishEmailSignup] = useState(false);
    const text = useText();

    async function logout() {
        return auth.signOut()
    }

    const loginWithEmailLink = useMemo(() => async (email: string) => {
        try {
            await signInWithEmailLink(auth, email, window.location.href)
            const message = text.login_login_success.replace('{name}', email);
            toast.success(message);

            setMagicLinkEmail(null);
        } catch (error: any) {
            const errorCode = error?.code;

            if (errorCode === 'auth/invalid-email') {
                toast.error(text.login_emailMismatch);
            } else if (errorCode === 'auth/invalid-action-code') {
                toast.error(text.login_invalidActionCode);
            } else {
                console.log('e', errorCode)
                toast.error(text.errorDefault);
            }
            setShowFinishEmailSignup(false);
        }
    }, [auth, text.errorDefault, text.login_emailMismatch, text.login_invalidActionCode, text.login_login_success]);

    useEffect(() => {
        // Falback to cover the IPhone bug when AuthSnapshot will not get triggered in some cases
        setTimeout(() => { setLoading(false) }, 3000)

        const unsubscribe = auth.onAuthStateChanged(async (firebaseAuthUser) => {
            if (firebaseAuthUser) {
                const result = await firebaseAuthUser.getIdTokenResult();
                const token = await firebaseAuthUser.getIdToken();
                const isSuperAdmin = (!!result?.claims?.superAdmin) || false;
                const uid = firebaseAuthUser.uid;

                const authUser: AuthUser = {
                    id: uid,
                    isSuperAdmin: isSuperAdmin,
                    staff: result?.claims?.staff + '' || '',
                    email: firebaseAuthUser.email || '',
                    user: { id: uid },
                    token,
                }
                setCurrentUser(authUser)
            } else {
                setCurrentUser(null)
            }
            setLoading(false)
        })

        return unsubscribe
    }, [auth, db])

    useEffect(() => {
        const uid = currentUser?.id;
        if (!uid) {
            return;
        }

        const userRef = doc(db, Collections.users, uid);
        return onSnapshot(userRef, async (userDataDoc) => {
            const newUserData = fromFirebaseDoc<User>(userDataDoc);
            if (!newUserData) {
                await setDoc(userRef, { id: currentUser.id });
                return;
            }
            setCurrentUser(prev => ({ ...prev, user: newUserData } as AuthUser));
        })
    }, [db, currentUser?.id])

    useEffect(() => {
        if (finishEmailSignup) {
            const email = getMagicLinkEmail();
            if (email) {
                loginWithEmailLink(email);
            } else {
                setShowFinishEmailSignup(true);
            }
        }
    }, [finishEmailSignup, loginWithEmailLink]);


    useEffect(() => {
        if (!currentUser) {
            setUserDeals({ dealTrackers: {} });
            return;
        }
        const userDealsRef = doc(db, Collections.userdeals, currentUser.id);

        return onSnapshot(userDealsRef, (doc) => {
            if (doc.exists()) {
                const userDeals = doc.data() as UserDeals;
                setUserDeals(userDeals);
            }
        });

    }, [db, currentUser]);

    const getAuthToken = async () => {
        const token = await auth.currentUser?.getIdToken();
        return token || '';
    }

    const value: AuthContext = {
        currentUser,
        userDeals,
        logout,
        getMagicLinkEmail,
        setMagicLinkEmail,
        loginWithEmailLink,
        getAuthToken
    }

    if (loading) {
        return <div className="center h-100">
            <Loading />
        </div>
    }

    return (
        <authContext.Provider value={value} >
            {children}
            <FinishEmailLoginModal show={showFinishEmailSignup} onHide={() => setShowFinishEmailSignup(false)} />
        </authContext.Provider>
    )
}

const getMagicLinkEmail = () => window.localStorage.getItem(LOCAL_STORAGE_KEYS.MAGIC_LINK_EMAIL) || null;

const setMagicLinkEmail = (email: string | null) => {
    if (email) {
        window.localStorage.setItem(LOCAL_STORAGE_KEYS.MAGIC_LINK_EMAIL, email);
    } else {
        window.localStorage.removeItem(LOCAL_STORAGE_KEYS.MAGIC_LINK_EMAIL);
    }
}